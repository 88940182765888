import React, { Component } from "react";
import { DatePicker } from '@mui/lab';
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ownStore } from "../../../AppOwnState/ownState";
import { TextField } from "@mui/material";

 
class FactsDatePicker extends Component {
    constructor(props) {
        super(props);
        let dateValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." + props.bind + "]");
        this.state = {
            dateValue: (dateValueFromState === null ? "" : dateValueFromState)
        }
        this.finalValue = dateValueFromState;

    }
    
    componentDidMount() {
        this.mounted = true;
        this.unsubscribe = ownStore.subscribe((storeInfo) => {
            if (this.mounted) {
                 
                if(storeInfo.dset === "this" || storeInfo.dset === this.props.dset) { 
                let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.props.bind + "]", this.props.refData);
                if (this.finalValue !== newState) {

                    if (newState === null) newState = "";
                    this.finalValue = newState;

                    this.setState({
                        dateValue: newState
                    });

                }
            }
            }
        });

    }



    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
        this.mounted = false;

    }

    async handleOnChange(date) {
        this.setState({
            dateValue: date
        }, async () => {
            await this.changeBinding()
            await ExecuteLayoutEventMethods(this.props.whenchange);
        });

    }
    async changeBinding() {
        if (this.props.dset && this.props.bind) {
            await ExecuteLayoutEventMethods([
                {
                    exec: "setdatasetfield",
                    args: {
                        dset: this.props.dset,
                        fieldname: this.props.bind,
                        data: this.state.dateValue
                    }
                }
            ]);
        }
    }
    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker 
                    inputFormat={this.props?.format}
                    {...this.props} 
                    value={this.state.dateValue} 
                    onChange={(date) => this.handleOnChange(date)}
                    renderInput={(props)=> <TextField {...props} />}  
                />
          </LocalizationProvider>
        );
    }
}
export default FactsDatePicker;