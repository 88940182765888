import React, { Component } from "react";

import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import ListViewTaskTemplate from "../ControlTemplates/ListViewItem/listViewTaskTemplate";
import './factsSwipeList.css';
// import store from "../../../AppRedux/store";
import { ExecuteLayoutEventMethods, objectMatchAll, GetControlPropertyFromStoreOrRefData } from '../../../General/commonFunctions'
import Icon from "@mui/material/Icon";
import ListViewCustomTemplate from "../ControlTemplates/ListViewItem/listViewCustomTemplate";
import { ownStore } from "../../../AppOwnState/ownState";

class FactsSwipeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listds: []
        };
    }

    ripOffControlSpecificAttributes() {

        const excluded = ["dset", "filterto", "refData", "content", "left", "right", "itemprops"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));
    }

    filterDataset(listData) {
        if (this.props.filterto && listData) {
            let filterTo = GetControlPropertyFromStoreOrRefData(this.props.filterto, this.props.refData);
            if (filterTo) {
                return listData.filter(it => {
                    return objectMatchAll(it, filterTo, this.props.satisfy ?? "every", this.props.filtermode, this.props.filterignorecase);
                });
            }
            else return listData;
        }
        else return listData;
    }

    componentDidMount() {
         
        this.mounted = true;
        if (this.props.dset) {
            let listData = ownStore.getState(this.props.dset);

            this.setState({
                listds: this.filterDataset(listData)
            });


            this.unsubscribe = ownStore.subscribe((storeInfo) => {
                if (this.mounted) {
                    if (storeInfo.dset === "this" || storeInfo.dset === this.props.dset ||
                        this.props.watch?.includes(storeInfo.dset)) {
                        let listData = ownStore.getState(this.props.dset);
                        let filteredData = this.filterDataset(listData);
                        // if (JSON.stringify(filteredData) !== JSON.stringify(this.state.listds)) {
                            this.setState({
                                listds: filteredData
                            });
                        // }
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();
    }

    render() {
        let newProps = this.ripOffControlSpecificAttributes();
        return (<div {...newProps}>
            {
                this.state.listds && this.state.listds?.length > 0 ?
                    <SwipeableList threshold={0.25}  >
                        {
                            this.state.listds.map &&
                            this.state.listds.map((item, index) => {

                                let contentTemplate = this.getContentTemplate(this.props.content, item, index);
                                let leftSwipe = this.props.left;
                                let rightSwipe = this.props.right;
                                let disLeft = GetControlPropertyFromStoreOrRefData(this.props.itemprops?.disableleft, item);
                                if (disLeft) leftSwipe = null;
                                let disRight = GetControlPropertyFromStoreOrRefData(this.props.itemprops?.disableright, item);
                                if (disRight) rightSwipe = null;
                                return (
                                    <div key={index} style={this.props.itemprops?.style}  >
                                        <SwipeableListItem
                                            swipeRight={leftSwipe && {
                                                content:
                                                    <div className="swplist left" style={leftSwipe.props?.style}>
                                                        <Icon>{leftSwipe.icon}</Icon>
                                                        <span>{leftSwipe.text}</span>
                                                    </div>,
                                                action: async () => {
                                                    await ExecuteLayoutEventMethods(leftSwipe.props.whenswipe, item);
                                                }
                                            }}
                                            swipeLeft={rightSwipe && {
                                                content:
                                                    <div className="swplist right" style={rightSwipe.props?.style}>
                                                        <span>{rightSwipe.text}</span>
                                                        <Icon>{rightSwipe.icon}</Icon>
                                                    </div>,
                                                action: async () => {
                                                    await ExecuteLayoutEventMethods(rightSwipe.props.whenswipe, item);
                                                }
                                            }}
                                        // onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
                                        >
                                            {contentTemplate}
                                        </SwipeableListItem>
                                    </div>

                                );
                            })

                        }
                    </SwipeableList>
                    :
                    <div>{this.props?.emptytext}</div>
            }
        </div>);
    }

    getContentTemplate(content, item, index) {
        switch (content.template) {
            case "lvitask":
                return (<ListViewTaskTemplate key={index} itemProps={content.props} itemObject={item} />);
            case "custom":
                return (<ListViewCustomTemplate key={index} itemProps={content.props} layout={content.chld} itemObject={item} />);

            default: return (<div {...content.props} />);
        }
    }
}

export default FactsSwipeList;