import { DataGrid } from "devextreme-react"
import { Column, Editing, Grouping, GroupPanel, Sorting} from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import { ownStore } from "../../../AppOwnState/ownState";
import { ChangePageDataSetState, ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import PureJSComponentFactory from "../../Pages/Factory/pureJSComponentFactory";
 
export const FactsDevGrid = (props) => {

    const getCurrentDataSet = () => {
        let listds = ChangePageDataSetState(props.datasets);
        if (!listds) return null;
        return listds[props.datasets[0]];
    }
    const getCurrentColumns = (data) => {
        let columninfo = props?.columnspecs ?? GetControlPropertyFromStoreOrRefData("[" + props.columninfo?.dset + "]");
        if (!columninfo) return null; 
        return columninfo;
    }
    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [data, setData] = useState(getCurrentDataSet());

    const [columns, setColumns] = useState(getCurrentColumns(data));

    useEffect(() => {
        let unsubscribe = ownStore.subscribe((storeInfo) => {
            if (props.datasets[0] === storeInfo.dset || props.datasets[0] === "this" ||
                props.watch?.includes(storeInfo.dset)) {
                let newDS = getCurrentDataSet();
                setData(newDS);
            }
        });
        return () => {
            if (unsubscribe) unsubscribe();
        }
    });

    const handleRowDblClick = async (data) => {
        await ExecuteLayoutEventMethods(props.whenrowdblclick, data);
    }
    return (<DataGrid
        dataSource={data}
        // defaultColumns={columns}
        
        focusedRowEnabled={true}
        width="100%"
        allowColumnReordering={true}
        allowColumnResizing={true}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        
        onRowDblClick={(t) => handleRowDblClick(t?.data)}
        {...props}   >
        {  props?.allowSorting &&  
             <Sorting mode="multiple" />
            }
        {  props?.editable &&  
            <Editing

                mode="cell"
                allowUpdating={props.editable?.canupdate ?? true}
                allowAdding={props.editable?.canadd ?? true}
                allowDeleting={props.editable?.candelete ?? true}
            />}
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={autoExpandAll} />
         
        {columns?.map((t, i) => {
          
            return (<Column key={i} 
                
                caption={t[props.columninfo?.captionfield]}
                dataField={t[props.columninfo?.datafield]}
                width={t[props.columninfo?.widthfield]}
                
                editCellRender={(dataInfo) => {
                    console.log(dataInfo); 
                    const scaff = new PureJSComponentFactory().scaffoldComponent(
                        t[props.columninfo?.editprops?.chldfield], dataInfo);
                    if (scaff) return scaff;
                    else return <span>{dataInfo?.displayValue}</span>;
                }}
                 />);
        })}

    </DataGrid>);



}